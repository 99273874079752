import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.records,"footer-props":{
    'items-per-page-options': [10, 20, 30, 40, 50, 100],
  },"loading":_vm.loading,"disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
  var name = ref.item.name;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(name)+" ")])]}},{key:"item.admin",fn:function(ref){
  var adminEmails = ref.item.adminEmails;
return [_c('div',{staticClass:"admin-emails"},_vm._l((adminEmails),function(email){return _c('span',{key:email},[_vm._v(" "+_vm._s(email)+" ")])}),0)]}},{key:"item.subscriptions",fn:function(ref){
  var subscriptions = ref.item.subscriptions;
return [_c('div',{staticClass:"subscriptions"},[(subscriptions.secretary)?_c('span',[_vm._v(" Company Secretary ")]):_vm._e(),(subscriptions.compliance)?_c('span',[_vm._v(" Compliance ")]):_vm._e(),(subscriptions.officerAppointments)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" Officer Appointments ")]):_vm._e()])]}},{key:"item.active",fn:function(ref){
  var ref_item = ref.item;
  var id = ref_item.id;
  var isActive = ref_item.isActive;
return [_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},on:{"click":function($event){return _vm.onActiveStatusClick(id)}},model:{value:(isActive),callback:function ($$v) {isActive=$$v},expression:"isActive"}})]}},{key:"item.actions",fn:function(ref){
  var id = ref.item.id;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('ac-icon-btn',{attrs:{"text":"Check Condition ","color":"primary","btn-class":"me-1","disabled":"","icon":_vm.icons.mdiTextBoxSearchOutline}}),_c('ac-icon-btn',{attrs:{"text":"Delete","color":"error","icon":_vm.icons.mdiDelete},on:{"click":function($event){return _vm.onRemove(id)}}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }