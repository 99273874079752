<template>
  <v-data-table
    :headers="headers"
    :items="records"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50, 100],
    }"
    :loading="loading"
    disable-sort
  >
    <template #[`item.name`]="{ item: { name } }">
      <div class="text-no-wrap">
        {{ name }}
      </div>
    </template>
    <template #[`item.admin`]="{ item: { adminEmails } }">
      <div class="admin-emails">
        <span v-for="email in adminEmails" :key="email"> {{ email }} </span>
      </div>
    </template>
    <template #[`item.subscriptions`]="{ item: { subscriptions } }">
      <div class="subscriptions">
        <span v-if="subscriptions.secretary"> Company Secretary </span>
        <span v-if="subscriptions.compliance"> Compliance </span>
        <span v-if="subscriptions.officerAppointments" class="text-no-wrap">
          Officer Appointments
        </span>
      </div>
    </template>
    <template #[`item.active`]="{ item: { id, isActive } }">
      <v-checkbox
        v-model="isActive"
        hide-details
        class="pa-0 ma-0"
        @click="onActiveStatusClick(id)"
      />
    </template>
    <template #[`item.actions`]="{ item: { id } }">
      <div class="d-flex align-center justify-center">
        <ac-icon-btn
          text="Check Condition "
          color="primary"
          btn-class="me-1"
          disabled
          :icon="icons.mdiTextBoxSearchOutline"
        />
        <ac-icon-btn text="Delete" color="error" :icon="icons.mdiDelete" @click="onRemove(id)" />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiTextBoxSearchOutline, mdiDelete } from '@mdi/js'

export default {
  name: 'CompaniesTable',

  components: {
    AcIconBtn: () => import('@/components/AcIconBtn'),
  },

  props: {
    records: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    icons: {
      mdiTextBoxSearchOutline,
      mdiDelete,
    },
    headers: [
      { text: 'NAME', value: 'name' },
      { text: 'ADMIN', value: 'admin' },
      { text: 'CE NUMBER', value: 'ceNumber' },
      { text: 'SUBSCRIPTIONS', value: 'subscriptions' },
      { text: 'LICENSES', value: 'licenses' },
      { text: 'ACTIVE', value: 'active' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  methods: {
    ...mapActions('companies', ['toggleCompanyActiveStatus']),

    async onActiveStatusClick(id) {
      const { success, message } = await this.toggleCompanyActiveStatus(id)

      const notificationType = success ? 'success' : 'error'
      const position = 'bottom'
      this.AcNotify({
        type: notificationType,
        message,
        position,
      })
    },

    onRemove(id) {
      this.$emit('onRemove', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-emails,
.subscriptions {
  span:not(:last-of-type)::after {
    content: ',';
  }
}
</style>
